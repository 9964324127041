import React, { useEffect, useState } from 'react'

import Image from 'next/image'

import classnames from 'classnames/bind'

import SiteApi from 'Services/Site'

import Facebook from 'public/social/facebook.svg'
import Instagram from 'public/social/instagram.svg'
import LinkedIn from 'public/social/linkedin.svg'
import Twitter from 'public/social/twitter.svg'
import Youtube from 'public/social/youtube-text.svg'

import styles from './Footer.module.css'

import Heading from '../Heading'
import Link from '../Link'

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
}

const cx = classnames.bind(styles)

function Footer() {
  const [cols, setCols] = useState()
  const [legals, setLegals] = useState()
  const [socials, setSocials] = useState()

  const year = new Date().getFullYear()

  const socialIcons = {
    Facebook: Facebook,
    LinkedIn: LinkedIn,
    Youtube: Youtube,
    Twitter: Twitter,
    Instagram: Instagram,
  }

  useEffect(() => {
    const site = SiteApi.getFooterNavs().data
    if (site) {
      if (site.cols) {
        setCols(site.cols)
      }
      if (site.legals) {
        setLegals(site.legals)
      }
      if (site.socials) {
        setSocials(site.socials)
      }
    }
  }, [])

  const Socials = () => {
    return (
      <>
        {socials && (
          <div className="flex items-center space-x-3">
            {socials.map((social, index) => {
              if (socialIcons[social.title]) {
                const Icon = socialIcons[social.title]

                return (
                  <div key={index} className="h-8 w-8 text-white">
                    <Link href={social.link} button={false} blank title={`Titans of CNC on ${social.title}`}>
                      <Icon className="bg-primary p-1 rounded-md" />
                    </Link>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        )}
      </>
    )
  }

  return (
    <footer className="bg-titan-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <div className="w-40">
              <Image
                className={styles['logo-image']}
                src={'/cnc-expert-logo.png'}
                alt={'CNC Expert - Powered by TITANS of CNC'}
                width={350}
                height={200}
                priority
              />
            </div>
            <div className="flex space-x-6">
              <Socials />
            </div>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-8">
              <div>
                <h3 className="text-xl font-semibold leading-6 text-white uppercase">Get In Touch</h3>
                <ul role="list" className="mt-6 space-y-1">
                  {cols?.[0].items.map((item) => (
                    <li key={item.title}>
                      <a href={item.link} className="leading-6 text-gray-300 hover:text-white">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-xl font-semibold leading-6 text-white uppercase">Certifications</h3>
                <ul role="list" className="mt-6 space-y-1">
                  {cols?.[1].items.map((item) => (
                    <li key={item.title}>
                      <a href={item.link} className="leading-6 text-gray-300 hover:text-white">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-xl font-semibold leading-6 text-white uppercase">Titan Network</h3>
                <ul role="list" className="mt-6 space-y-1">
                  {cols?.[2].items.map((item) => (
                    <li key={item.title}>
                      <a href={item.link} className="leading-6 text-gray-300 hover:text-white">
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8"></div>
          </div>
        </div>
        <div className="mt-16 border-t border-titan-gray-500 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs text-center leading-5 text-titan-gray-500">
            &copy; {`Copyright ${year}. All Rights Reserved.`}{' '}
            <Link className="text-xs leading-5 text-titan-gray-500" href="/CNCExpert-PrivacyPolicy.pdf">
              Privacy
            </Link>{' '}
            |{' '}
            <Link className="text-xs leading-5 text-titan-gray-500" href="/CNCExpert-TermsOfService.pdf">
              Terms of Use
            </Link>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
