import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import styles from './Heading.module.css'
let cx = classnames.bind(styles)

const Heading = ({
  element,
  size,
  lite,
  bold,
  highlight,
  children,
  className,
  tight,
  upper,
  nomargin,
  noitalic,
  center,
  block,
  subtitle,
}) => {
  const HeadingTag = `${element}`
  const size_class = size ? size : element
  const class_name = className ? className : ''

  const classes = cx(`${size_class}`, `${class_name}`, {
    'h1-6': true,
    highlight: highlight,
    lite: lite,
    bold: bold,
    tight: tight,
    upper: upper,
    nomargin: nomargin,
    center: center,
    noitalic: noitalic,
    block: block,
    'has-subtitle': subtitle,
  })

  return (
    <HeadingTag className={classes}>
      {children}
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </HeadingTag>
  )
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4-5', 'h4', 'h5', 'h6']),
  lite: PropTypes.bool,
  bold: PropTypes.bool,
  block: PropTypes.bool,
  center: PropTypes.bool,
  highlight: PropTypes.bool,
}

export default Heading
