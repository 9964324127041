import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { toast as HotToast, useToaster } from 'react-hot-toast/headless'

export default function toast(type, message, description, options) {
  HotToast(
    (t) => (
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {type === 'success' && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
          {type === 'error' && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
          {type === 'warning' && <ExclamationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />}
          {type === 'info' && <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />}
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <div className="text-xs mb-0 font-medium text-gray-900">{message}</div>
          {description && <div className="mt-1 text-xs text-gray-500 mb-0">{description}</div>}
        </div>
        <div className="ml-4 flex flex-shrink-0">
          <button
            type="button"
            className="p-0 inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={() => {
              HotToast.dismiss(t.id)
            }}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    ),
    options
  )
}

export function ToastWrapper() {
  const { toasts, handlers } = useToaster()
  const { startPause, endPause, calculateOffset, updateHeight } = handlers

  return (
    <div
      style={{
        zIndex: '999999999',
        position: 'fixed',
        bottom: 100,
        right: 8,
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((t) => {
        const offset = calculateOffset(t, {
          reverseOrder: false,
          gutter: 8,
          defaultPosition: 'bottom-right',
        })

        const ref = (el) => {
          if (el && typeof t.height !== 'number') {
            const height = el.getBoundingClientRect().height
            updateHeight(t.id, height)
          }
        }
        return (
          <div
            key={t.id}
            ref={ref}
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '380px',
              transition: 'all 0.5s ease-out',
              opacity: t.visible ? 1 : 0,
              transform: `translateY(-${offset}px)`,
            }}
            {...t.ariaProps}
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              {/* @ts-ignore*/}
              <div className="p-4">{t.message(t)}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
