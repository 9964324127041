import { useEffect } from 'react'

import Router from 'next/router'
import Script from 'next/script'

import { SessionProvider } from 'next-auth/react'
import { IntercomProvider } from 'react-use-intercom'

import { GA_TRACKING_ID, pageview } from 'Utils/pixels'

// import { AuthProvider } from 'Services/Auth';
// import { UserContextProvider } from 'Services/User'
// import { SessionProvider } from 'next-auth/react'
import MainLayout from 'components/Layout'

import 'styles/globals.css'
import 'aos/dist/aos.css'
import '@egjs/react-flicking/dist/flicking.css'
import '@egjs/react-flicking/dist/flicking-inline.css'
import { ToastWrapper } from '@/components/Toast'

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID
const IS_DEV = process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'

function CNCExpert({ Component, pageProps: { session, ...pageProps } }) {
  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  const getLayout = Component.getLayout || ((page) => page)

  const hideFooter = Component?.layoutOptions?.hide_footer || false
  const hideIntercom = Component?.layoutOptions?.hide_intercom || false

  return (
    <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {/* <Script
        id="insights-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.AlgoliaAnalyticsObject = 'aa';
            window.aa = window.aa || function() {
              (window.aa.queue= window.aa.queue||[]).push(arguments)
            }
            window.aa.version = '2.4.0'
          `,
        }}
      />
      <Script
        src="https://cdn.jsdelivr.net/npm/search-insights@2.4.0/dist/search-insights.min.js"
        strategy="lazyOnload"
        onLoad={() => {
          window.aa('init', {
            appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
            apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_KEY,
            useCookie: false,
          })
        }}
      /> */}
      <Script src="https://upload-widget.cloudinary.com/global/all.js" type="text/javascript" />
      <SessionProvider session={session}>
        <IntercomProvider appId={INTERCOM_APP_ID} initializeDelay shouldInitialize={!IS_DEV && !hideIntercom}>
          <MainLayout hideFooter={hideFooter}>{getLayout(<Component {...pageProps} />)}</MainLayout>
          <ToastWrapper />
        </IntercomProvider>
      </SessionProvider>
    </>
  )
}

export default CNCExpert
