import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

import NextLink from 'next/link'

import styles from './Link.module.css'
let cx = classnames.bind(styles)

const Link = ({
  href,
  className,
  centered,
  children,
  button,
  button_desktop,
  underline,
  primaryHighlight,
  highlight,
  highlightText,
  active,
  dark,
  light,
  header,
  bold,
  blank,
  large,
  small,
  spacing,
  block,
  title,
}) => {
  const CenterWrapper = (children) => {
    return <div className={`${styles['btn-wrapper']} ${styles['btn-wrapper-center']}`}>{children}</div>
  }

  const NormalLink = () => {
    return (
      <a href={href} className={classes}>
        {children}
      </a>
    )
  }

  const notValid = () => {
    if (typeof hrefAndAs(href).as === 'undefined') {
      return true
    }
  }

  const LinkWrapper = ({ condition, wrapper, children }) => {
    if (condition) {
      return wrapper(children)
    } else {
      return children
    }
  }

  const class_name = className ? className : ''

  const classes = cx(
    {
      link: true,
      'link-header': header,
      'link-bold': bold,
      'link-block': block,
      btn: button,
      'btn-primary': button,
      'btn-desktop': button_desktop,
      'btn-primary-desktop': button_desktop,
      'btn-primary-color': primaryHighlight,
      'btn-underline': underline,
      'btn-highlight': highlight,
      'btn-active': active,
      'btn-highlight-text': highlightText,
      'btn-dark': dark,
      'btn-light': light,
      'btn-large': large,
      'btn-small': small,
      'btn-spacing': spacing,
    },
    `${class_name}`
  )

  let other_attributes = {}

  if (blank) {
    other_attributes.target = '_blank'
  }

  if (title) {
    other_attributes.title = title
    other_attributes['aria-label'] = title
  }

  return (
    <LinkWrapper condition={centered} wrapper={CenterWrapper}>
      <NextLink href={href} shallow={false}>
        <a className={classes} {...other_attributes}>
          {children}
        </a>
      </NextLink>
    </LinkWrapper>
  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  header: PropTypes.bool,
  centered: PropTypes.bool,
  button: PropTypes.bool,
  highlight: PropTypes.bool,
  highlightText: PropTypes.bool,
  underline: PropTypes.bool,
  blank: PropTypes.bool,
  bold: PropTypes.bool,
}

export default Link
